<template>
  <v-main align="center">
    <StaticDashboardStatus v-if="chooseDashboard" />

    <v-row>
      <v-col cols="12" :md="permitted('Dashboard.View') ? 6 : 12" sm="12">
        <v-card class="mt-2 ml-2" style="height: 98%">
          <v-card-title>{{ $t("dashboard.navigationTitle") }}</v-card-title>
          <v-card-text>
            <DashboardCategoryViewer
              style="max-height: 100%; overflow-y: scroll; height: 100%"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <div>
          <v-card class="mt-2 mr-2" v-if="permitted('Dashboard.Create')">
            <v-card-title>{{ $t("dashboard.createTitle") }}</v-card-title>

            <v-form lazy-validation v-model="valid" ref="form">
              <v-card-text>
                <v-text-field
                  :label="$t('dashboard.fields.name')"
                  prepend-icon="mdi-domain"
                  type="text"
                  v-model="dashboardName"
                  :rules="req"
                ></v-text-field>
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary" text @click="submitCreate()">
                  {{ $t("common.create") }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>

          <v-card class="mt-4 mr-2" v-if="permitted('Dashboard.Update')">
            <v-card-title>{{ $t("dashboard.copyDeleteTitle") }}</v-card-title>

            <v-form lazy-validation v-model="valid" ref="form">
              <v-card-text>
                <v-select
                  autocomplete="off"
                  :label="$t('dashboard.name')"
                  v-model="dashboardMod"
                  :items="dashboards"
                  item-text="name"
                  item-value="dashboardId"
                  prepend-icon="mdi-calendar-today"
                  required
                />
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  :disabled="!dashboardMod"
                  @click.stop="confirmCopy = true"
                  >{{ $t("common.copy") }}</v-btn
                >

                <v-dialog v-model="confirmCopy" max-width="90%">
                  <v-card>
                    <v-card-title class="headline">
                      {{ $t("common.verify") }}
                    </v-card-title>

                    <v-card-text>
                      <v-text-field
                        :label="$t('dashboard.fields.name')"
                        prepend-icon="mdi-domain"
                        type="text"
                        v-model="dashboardNameCopy"
                        :rules="req"
                      ></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary lighten-1"
                        text
                        @click="confirmCopy = false"
                        >{{ $t("common.disagree") }}</v-btn
                      >

                      <v-btn color="primary" text @click="submitCopy">{{
                        $t("common.accept")
                      }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-btn
                  color="error"
                  :disabled="!dashboardMod"
                  text
                  @click="confirmDelete = true"
                  >{{ $t("common.delete") }}</v-btn
                >
                <v-dialog v-model="confirmDelete" max-width="450px">
                  <v-card>
                    <v-card-title class="headline">
                      {{ $t("common.verify") }}
                    </v-card-title>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary lighten-1"
                        text
                        @click="confirmDelete = false"
                        >{{ $t("common.disagree") }}</v-btn
                      >

                      <v-btn color="primary" text @click="submitDelete">{{
                        $t("common.accept")
                      }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-card-actions>
            </v-form>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <PublicDashboardList
      class="mt-4 mx-2"
      v-if="permitted('PublicDashboard.View')"
    />
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { format } from "date-fns";

import { Roles } from "@/_helpers/Role";
import StaticDashboardStatus from "@/components/common/StaticDashboardStatus";
import PublicDashboardList from "@/components/common/PublicDashboardList";
import DashboardCategoryViewer from "../components/dashboard/DashboardCategoryViewer.vue";

export default {
  name: "CleanDashboard",

  props: {
    dashboardId: null,
  },

  data() {
    return {
      chooseDashboard: true,
      dashboardMod: null,
      dashboardNameCopy: "",
      valid: false,
      confirmCopy: false,
      confirmDelete: false,
      dashboardName: "",
      req: [(v) => !!v || this.$t("dashboard.nameReq")],
    };
  },
  computed: {
    ...mapState("users", ["status", "currentUser", "userCompany"]),
    ...mapState("dashboards", [
      "dashboards",
      "defaultDashboard",
      "currentDashboard",
    ]),

    Roles() {
      return Roles;
    },
  },

  methods: {
    ...mapActions("dashboards", [
      "getDashboards",
      "create",
      "duplicate",
      "delete",
    ]),
    ...mapActions("modules", ["setModules"]),

    humanDate(date) {
      return date !== undefined
        ? format(new Date(date), "dd MMM - HH:mm")
        : "N/A";
    },

    async submitDelete() {
      // Delete dashboardMod
      await this.delete(this.dashboardMod);

      this.dashboardMod = null;
      this.confirmDelete = false;
    },

    async submitCopy() {
      // copy dashboardMod with name of dashboardNameCopy
      await this.duplicate({
        dashboardId: this.dashboardMod,
        payload: { name: this.dashboardNameCopy },
      });

      this.dashboardMod = null;
      this.confirmCopy = false;
    },

    async submitCreate() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      await this.create({ payload: { name: this.dashboardName } });
      await this.getDashboards();
      this.$router.go();
    },
  },

  async created() {
    await this.getDashboards();
  },

  watch: {
    chooseDashboard(val) {
      if (val) {
        this.setModules([]);
      }
    },
  },

  components: {
    StaticDashboardStatus,
    PublicDashboardList,
    DashboardCategoryViewer,
  },
};
</script>
